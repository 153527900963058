<template>
  <g>
    <svg:style>
      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }
      .handle { fill: url(#handle-gradient); }
    </svg:style>
    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 70.81"
      :y1="doorTopHeight1 + 93.13"
      :x2="doorLeftWidth1 + 70.81"
      :y2="doorTopHeight1 + 50.73"
      xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
      :x1="doorLeftWidth1 + 70.81"
      :y1="doorTopHeight1 + 88.1"
      :x2="doorLeftWidth1 + 70.81"
      :y2="doorTopHeight1 + 55.58"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
      <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
      </filter>

    <g id="S04">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 50.73} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 50.73} ${doorLeftWidth1 + 49.61} ${doorTopHeight1 + 93.13} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 93.13} ${doorLeftWidth1 + 92.02} ${doorTopHeight1 + 50.73}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-5"
                :points="`${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 55.58} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 55.58} ${doorLeftWidth1 + 54.56} ${doorTopHeight1 + 88.1} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 88.1} ${doorLeftWidth1 + 87.07} ${doorTopHeight1 + 55.58}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
